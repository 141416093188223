import { Component, ViewEncapsulation } from '@angular/core';
import { GoogleAnalyticsService } from 'accorto';

@Component({
  selector: 'c4sf-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  title = 'Checklist for Salesforce';

  constructor(ga: GoogleAnalyticsService) {
  }

}
