import { Graph } from './graph';
import { CheckList } from './check-list';
import { CResponse } from 'accorto';

/* GenTsClass client: com.checklist4sf.domain.Check4SfResponse */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: Check4SfResponse
 */
export class Check4SfResponse extends CResponse {

  /**
   * List result
   * label: CheckLists
   */
  public checklists: CheckList[] = [];

  /**
   * Query Result
   * label: Checklist Graph
   */
  public graph?: Graph; // optional=null

  /**
   * separate login
   * label: Session Id
   */
  public sid?: string; // optional=null

  /**
   * label: Last Saved Id
   */
  public savedId?: string; // optional=null

} // Check4SfResponse
