/**
 * Graph Utility
 */
import {DataRecord, Logger, Point2d} from 'accorto';
import {Graph} from '../model/graph';
import {GraphNode} from '../model/graph-node';
import {CheckListItem} from '../model/check-list-item';
import {GraphLink} from '../model/graph-link';

/**
 * Calculate + Maintain Graph
 */
export class GraphUtil {

  /** End Time in Days */
  public endTime: number = 0;

  /** Maximum target dateOffsetDays (positive) */
  maxTarget: number = 0;
  /** Maximum before reference to target */
  maxPreTarget: number = 0;

  private log: Logger = new Logger('GraphUtil');

  constructor(public graph: Graph) {
  }

  /**
   * Add Link
   * @param startNode start
   * @param endNode end
   */
  addLink(startNode: GraphNode, endNode: GraphNode): string {
    // add
    if (startNode.sfId) {
      endNode.prerequisiteSet.add(startNode.sfId);
      this.updateNodeFromSets(endNode);
    }
    if (endNode.sfId) {
      startNode.dependentSet.add(endNode.sfId);
      this.updateNodeFromSets(startNode);
    }
    //
    this.unselectNodes();

    return 'Added link from "' + startNode.prefix()
      + '" to "' + endNode.prefix() + '"';
  } // addLink

  /**
   * @param node new node
   */
  addNode(node: GraphNode): void {
    this.graph.nodes.push(node);
  }

  /**
   * Calculate
   * - maxTarget
   * - node.dateOffsetDays
   * then:
   * - cycles
   * - endTime, node.startOffset, node.title (prerequisites)
   *
   * <pre>
   * Target Date
   * | 1 |
   *     | 2 |
   *         | -7 |     - dependent on maxPreTarget (=3)
   *         | -1 |
   *              | 3 | - dependent on maxTarget (=-7) + maxNonTarget (=3)
   * </pre>
   * - calculate maxTarget - max of target offset [calculate]
   * - calculate maxPreTarget - max before target [calculateRelativeStart]
   * https://www.lucidchart.com/invitations/accept/6dd0e508-196d-4f4f-8941-161f880d704f
   * @return error message
   */
  calculate(): string | undefined {
    // reset
    this.maxTarget = 0;
    this.graph.nodes.forEach((n) => {
      n.isSelected = false;
      n.startOffset = 0;
      n.title = n.prefix();
      //
      // n.sfId = n.record.value('id');
      //
      n.prerequisiteIds = [];
      n.dependentIds = [];
      n.prerequisiteSet = new Set<string>();
      n.dependentSet = new Set<string>();

      // duration
      const days = n.record?.value('dateOffsetDays');
      if (days != null && days !== '') {
        n.dateOffsetDays = Number(days);
        if (n.dateOffsetDays < 0) { // target date
          if (this.maxTarget > n.dateOffsetDays) {
            this.maxTarget = n.dateOffsetDays;
          }
        }
      }
      if (n.dateOffsetDays == null) {
        n.dateOffsetDays = 0;
      }
    });
    this.maxTarget = Math.abs(this.maxTarget);

    this.calculatePrerequisites();
    const error = this.hasCycle();
    if (error) {
      return error;
    }

    this.calculateRelativeStart();
    return undefined;
  } // calculate

  /**
   * Change Duration
   * @param node node changed
   * @param add add a day
   */
  changeNodeDuration(node: GraphNode, add: boolean): void {
    const from = node.dateOffsetDays;
    if (node.dateOffsetDays && node.record) {
      if (add) {
        if (node.dateOffsetDays >= 0) { // std
          node.dateOffsetDays++; // increase length
        } else { // target
          if (node.dateOffsetDays < -1) {
            node.dateOffsetDays++; // closer to target date
          }
        }
      } else {
        if (node.dateOffsetDays >= 0) { // std
          if (node.dateOffsetDays > 1) {
            node.dateOffsetDays--; // decrease length
          }
        } else { // target
          node.dateOffsetDays--; // away from target date
        }
      }
      node.record.changeMap.dateOffsetDays = String(node.dateOffsetDays);
    }
    this.log.debug('changeNodeDuration', 'from ' + from
      + (add ? ' add ' : ' dec ') + node.dateOffsetDays)();
  } // changeNodeDuration

  /**
   * @return changed records
   */
  changedRecords(): DataRecord[] {
    const records: DataRecord[] = [];
    for (const node of this.graph.nodes) {
      const changes = node.record?.getChanges();
      this.log.debug('doSave', node.prefix(), changes)();
      if (node.record?.isChanged()) {
        records.push(node.record);
      }
    }
    return records;
  } // changedRecords

  /**
   * Create Links - called from Layout
   */
  createLinks(): GraphLink[] {
    const links: GraphLink[] = [];
    const corners: Point2d[] = [];
    let index = 0;
    this.graph.nodes.forEach((n) => {
      if (n.prerequisiteIds) {
        n.prerequisiteIds.forEach((id) => {
          const p = this.graph.getNode(id);
          if (p) {
            const link = new GraphLink(p, n, index++);
            link.nodes = this.graph.nodes;
            link.label = p.prefix() + ' -> ' + n.prefix();
            corners.push(link.linkCorner());
            link.corners = corners;
            links.push(link);
            // console.debug('-- ' + link.label);
          }
        });
      }
    });
    return links;
  } // createLinks

  /**
   * @param link link to be deleted
   */
  deleteLink(link: GraphLink): void {
    // delete
    if (link.startNode.sfId) {
      link.endNode.prerequisiteSet.delete(link.startNode.sfId);
      this.updateNodeFromSets(link.endNode);
    }
    if (link.endNode.sfId) {
      link.startNode.dependentSet.delete(link.endNode.sfId);
      this.updateNodeFromSets(link.startNode);
    }
  }

  /**
   * @param deleteNode old node
   * @return updated remaining nodes
   */
  deleteNode(deleteNode: GraphNode): DataRecord[] {
    const deleteId = deleteNode.sfId;
    if (deleteId) {
      const index = this.graph.nodes.indexOf(deleteNode);
      this.graph.nodes.splice(index, 1);
      this.graph.nodes.forEach((n) => {
        n.prerequisiteSet.delete(deleteId);
        n.dependentSet.delete(deleteId);
        this.updateNodeFromSets(n);
      });
    }
    // changed records
    const records: DataRecord[] = [];
    for (const node of this.graph.nodes) {
      const changes = node.record?.getChanges();
      this.log.debug('doDelete', node.prefix(), changes)();
      if (node.record?.isChanged()) {
        records.push(node.record);
      }
    }
    return records;
  } // deleteNode

  /**
   * Get Checklist Sf Id if checklist loaded
   */
  getChecklistSfId(): string | undefined {
    if (this.graph && this.graph.checklist) {
      return this.graph.checklist.sfId;
    }
    return undefined;
  }

  /**
   * Is there a cycle
   * @param newFromId optional new from id
   * @param newToId optional new to id
   * @return error message or undefined
   */
  hasCycle(newFromId?: string, newToId?: string): string | undefined {
    // https://github.com/mission-peace/interview/blob/master/src/com/interview/graph/CycleInDirectedGraph.java
    const whiteNodeSet = new Set<GraphNode>(); // new
    const grayIdSet = new Set<string>(); // investigating
    const blackIdSet = new Set<string>(); // all visited
    // fill white
    this.graph.nodes.forEach((n) => {
      n.dependentIds = [];
      whiteNodeSet.add(n);
    });
    // build dependents from prerequisites
    this.graph.nodes.forEach((n) => {
      if (n.prerequisiteIds && n.sfId) {
        const thisId = n.sfId;
        n.prerequisiteIds.forEach((pid) => {
          const node = this.graph.getNode(pid);
          if (node) {
            if (!node.dependentIds) {
              node.dependentIds = [];
            }
            node.dependentIds.push(thisId);
          } else {
            this.log.warn('hasCycle', 'not found id=' + pid)();
          }
        });
      }
    });
    // add new link
    if (newFromId && newToId) {
      const newFromNode = this.graph.getNode(newFromId);
      if (newFromNode) {
        newFromNode.dependentIds.push(newToId);
      } else {
        this.log.warn('hasCycle', 'not found new from id=' + newFromId)();
      }
    }
    // search for cycles
    while (whiteNodeSet.size > 0) {
      const setIter = whiteNodeSet.entries();
      const currentNode: GraphNode = setIter.next().value[ 0 ];
      if (this.hasCycleDfs(currentNode, whiteNodeSet, grayIdSet, blackIdSet)) {
        // error - found a cycle
        let error: string = 'Invalid cycle';
        grayIdSet.forEach((value, key) => {
          const node = this.graph.getNode(value);
          if (node) {
            error += ' - ' + node.prefix();
          } else {
            error += ' - ' + value;
          }
        });
        return error;
      }
    }
    return undefined; // ok
  } // hasCycle

  isEmpty(): boolean {
    return this.graph.nodes.length === 0;
  }

  isUnchanged(): boolean {
    if (this.graph) {
      for (const n of this.graph.nodes) {
        if (n.record && n.record.isChanged()) {
          return false; // some change
        }
      }
    }
    return true; // nothing changed
  }

  /**
   * Layout - called after calculate
   * @param pageWidth page width
   * @param pageHeight page height
   * @param pageSize page size info
   */
  layout(pageWidth: number, pageHeight: number, pageSize: ClientRect): void {
    // x/y layout
    const nodeWidth = 100;
    const nodeHeight = 46;
    const margin = 5; // right/left/top
    const marginY = 20; // | between nodes
    //
    let xTimeFactor = (pageWidth - (2 * margin)) / this.endTime;
    if (isNaN(xTimeFactor) || !isFinite(xTimeFactor)) {
      xTimeFactor = 1;
    } else {
      xTimeFactor = Math.round(xTimeFactor * 10) / 10;
    }
    let y: number = margin; // margin for border draw
    this.graph.nodes.forEach((n) => {
      n.x = Math.round(n.startOffset * xTimeFactor) + margin;
      n.y = y;
      n.width = nodeWidth;
      const days = n.dateOffsetDays ? (n.dateOffsetDays > 0 ? n.dateOffsetDays : 1) : 1;
      n.width = Math.round(days * xTimeFactor);
      if (n.width < nodeWidth) {
        n.width = nodeWidth;
      }
      n.height = nodeHeight;
      //
      y += n.height + marginY;
    });
    this.log.debug('doLayout', 'nodes=' + this.graph.nodes.length
      + ' w=' + pageWidth
      + ' h=' + pageHeight
      + ' (' + pageSize.width + ',' + pageSize.height + ')'
      + ' endTime=' + this.endTime
      + ' xFactor=' + xTimeFactor
      + ' yMax=' + y)();
  } // layout

  /**
   * New Node
   */
  newNode(): GraphNode {
    const node = new GraphNode();
    node.item = new CheckListItem();
    node.item.isActive = true;
    const sfId = this.getChecklistSfId();
    if (sfId) {
      node.item.checkListSfId = sfId;
    }
    node.item.isDependentsAllComplete = true;
    node.item.isWorkdays = true;
    node.item.dateOffsetDays = 1;
    node.dateOffsetDays = node.item.dateOffsetDays; // current
    node.userInfo = 'Record Owner';
    return node;
  }

  /**
   * de-select all nodes
   */
  unselectNodes(): void {
    this.graph.nodes.forEach((n) => {
      n.isSelected = false;
    });
  }

  /**
   * Set prerequisite/dependent arrays + changeMap from sets
   * @param n node to be updated
   */
  updateNodeFromSets(n: GraphNode): void {
    n.prerequisiteIds = [];
    n.prerequisiteSet.forEach((vv) => {
      n.prerequisiteIds.push(vv);
    });
    n.dependentIds = [];
    n.dependentSet.forEach((vv) => {
      n.dependentIds.push(vv);
    });
    // record changes
    if (n.record) {
      if (n.prerequisiteIds.length > 0) {
        n.record.changeMap.prerequisiteIdList = n.prerequisiteIds.join(',');
        n.record.changeMap.prerequisiteSfId = n.prerequisiteIds[0];
      } else {
        n.record.changeMap.prerequisiteIdList = null;
        n.record.changeMap.prerequisiteSfId = null;
      }
      if (n.dependentIds.length > 0) {
        n.record.changeMap.dependentSfId = n.dependentIds[0];
      } else {
        n.record.changeMap.dependentSfId = null;
      }
      n.record.getChanges(); // clean up
    }
  } // updateNodeFromSets

  /**
   * Calculate Prerequisites
   */
  private calculatePrerequisites(): void {
    this.graph.nodes.forEach((n) => {
      const pre = n.record?.value('prerequisiteSfId');
      if (pre && pre !== '' && n.sfId) {
        const preNode = this.graph.getNode(pre);
        if (preNode) {
          preNode.dependentSet.add(n.sfId);
          n.prerequisiteSet.add(pre);
        } else {
          this.log.info('calculatePrerequisites', 'NotFound prerequisiteSfId=' + pre, n.toStringX())();
        }
      }
      const pres = n.record?.value('prerequisiteIdList');
      if (pres && pres !== '' && n.sfId) {
        const parts = pres.split(',');
        for (const pp of parts) {
          const preNode = this.graph.getNode(pp);
          if (preNode) {
            preNode.dependentSet.add(n.sfId);
            n.prerequisiteSet.add(pres);
          } else {
            this.log.info('calculatePrerequisites', 'NotFound prerequisiteIdList=' + pp, n.toStringX())();
          }
        }
      }
      const dep = n.record?.value('dependentSfId');
      if (dep && dep !== '' && n.sfId) {
        const depNode = this.graph.getNode(dep);
        if (depNode) {
          depNode.prerequisiteSet.add(n.sfId);
          n.dependentSet.add(dep);
        } else {
          this.log.info('calculatePrerequisites', 'NotFound dependentSfId=' + dep, n.toStringX())();
        }
      }
    });

    this.graph.nodes.forEach((n) => {
      this.updateNodeFromSets(n);
      // this.log.debug('calculatePrerequisites', n.toStringX())();
    });
  } // calculatePrerequisites

  /**
   * Calculate
   * - maxPreTarget
   * - endTime
   *
   * - node.startOffset
   * - node.title (prerequisites)
   *
   * sort
   */
  private calculateRelativeStart(): void {
    // nodes not dependent on target
    this.maxPreTarget = 0;
    this.graph.nodes.forEach((n) => {
      if (n.dateOffsetDays != null && n.dateOffsetDays >= 0) { // not target
        const so = this.getStartOffsetPre(n);
        if (so && so >= 0) {
          const end = so + (n.dateOffsetDays === 0 ? 1 : n.dateOffsetDays);
          // console.debug('--pre s=' + so + ' e=' + end + ' -- ' + n.item.name);
          if (this.maxPreTarget < end) {
            this.maxPreTarget = end;
          }
        }
      }
    });
    this.log.debug('calculateRelativeStart', 'maxTarget=' + this.maxTarget
      + ' maxPreTarget=' + this.maxPreTarget)();

    // calculate relative start
    this.endTime = 0;
    this.graph.nodes.forEach((n) => {
      n.startOffset = this.getStartOffset(n);
      const end = n.startOffset // time units | <0:2 =0>1 >0:days
        + (n.dateOffsetDays != null
          ? (n.dateOffsetDays > 0
            ? n.dateOffsetDays
            : (n.dateOffsetDays === 0
              ? 1
              : 2))
          : 1);
      if (this.endTime < end) {
        this.endTime = end;
      }
      // info
      n.title += 'start=' + n.startOffset;
      if (n.prerequisiteIds && n.prerequisiteIds.length > 0) {
        let prefix = '; prerequisites=';
        n.prerequisiteIds.forEach((id) => {
          const node = this.graph.getNode(id);
          n.title += prefix;
          if (node) {
            n.title += node.prefix();
          } else {
            n.title += id;
          }
          prefix = ', ';
        });
      }
      this.log.debug('calculateRelativeStart',
        n.item?.name + ': start=' + n.startOffset + ' end=' + end + ' days=' + n.dateOffsetDays)();
    });
    this.log.debug('calculateRelativeStart', 'end=' + this.endTime)();


    // -- node sequence --
    this.graph.nodes.sort((a: GraphNode, b: GraphNode) => {
      // (1) prerequisite|dependent
      if (this.isPrerequisite(a, b)) {
        //  console.debug(this.toStringSort(a, b, '<<', '(1)')); // a comes before b
        return -1;
      }
      if (this.isPrerequisite(b, a)) {
        //  console.debug(this.toStringSort(a, b, '>>', '(2)')); // b comes before a
        return 1;
      }

      // dependents
      if (a.dependentIds && a.dependentIds.length > 0) { // a has dependents
        //  console.debug(this.toStringSort(a, b, '[[', '(1d)')); // a comes before b
        return -1;
      }
      if (b.dependentIds && b.dependentIds.length > 0) {
        //  console.debug(this.toStringSort(a, b, ']]', '(2d)'));
        return 1;
      }

      if (a.prerequisiteIds && a.prerequisiteIds.length > 0) {
        //  console.debug(this.toStringSort(a, b, '{{', '(1p)'));
        return -1;
      }
      if (b.prerequisiteIds && b.prerequisiteIds.length > 0) {
        //  console.debug(this.toStringSort(a, b, '}}', '(2p)'));
        return 1;
      }

      // start time
      const sa = a.startOffset;
      const sb = b.startOffset;
      if (sa < sb) {
        //  console.debug(this.toStringSort(a, b, ' <t', '(1t)')); // a comes before b
        return -1;
      }
      if (sa > sb) {
        //  console.debug(this.toStringSort(a, b, 't>', '(2t)')); // b comes before a
        return 1;
      }
      // alpha
      const la = a.prefix();
      const lb = b.prefix();
      // console.debug(this.toStringSort(a, b, '--', 'a=' + la));
      return la.localeCompare(lb);
    }); // nodes.sort
  } // calculateRelativeStart

  /**
   * Get Start Offset
   * @param node start node
   * @return start offset
   */
  private getStartOffset(node: GraphNode): number {
    let startOffset: number = 0;
    if (node.dateOffsetDays != null) {
      if (node.dateOffsetDays < 0) { // target date
        startOffset = this.maxPreTarget // 7
          + this.maxTarget // 7
          + node.dateOffsetDays; // -1
        // console.debug('~startOffset: ' + startOffset
        //   + ' =' + this.maxPreTarget + '+' + this.maxTarget + '+' + node.dateOffsetDays
        //  + ' -- ' + node.item.name);
      } else if (node.prerequisiteIds) {
        node.prerequisiteIds.forEach((id) => {
          const pre = this.graph.getNode(id);
          if (pre) {
            // console.debug(this.toString(node) + ' pre: ' + this.toString(pre) + '(' + pre.startOffset + ')')
            let so = 0;
            if (pre.startOffset) {
              so = pre.startOffset;
            } else {
              so = this.getStartOffset(pre);
            }
            if (pre.dateOffsetDays != null) {
              if (pre.dateOffsetDays > 0) {
                so += pre.dateOffsetDays;
              } else if (pre.dateOffsetDays < 0) {
              } else {
                so += .1;
              }
            }
            if (so > startOffset) {
              startOffset = so;
            }
          }
          // console.debug('-startOffset: ' + startOffset + ' -- ' + node.item.name);
        });
      }
    }
    return startOffset;
  } // getStartOffset

  /**
   * Get Start Offset for nodes before nodes with target date
   * @param node the node
   * @return undfined if one of the prerequisites references the target date
   */
  private getStartOffsetPre(node: GraphNode): number | undefined {
    if (node.dateOffsetDays == null || node.dateOffsetDays < 0) { // target date
      return undefined; // target date
    }
    let startOffset: number = 0;
    if (node.prerequisiteIds) {
      // @ts-ignore
      node.prerequisiteIds.forEach((id: string) => {
        const pre = this.graph.getNode(id);
        if (pre) {
          // console.debug(this.toString(node) + ' pre: ' + this.toString(pre) + '(' + pre.startOffset + ')')
          let so: number | undefined = 0;
          if (pre.startOffset) {
            so = pre.startOffset;
          } else {
            so = this.getStartOffsetPre(pre);
            if (so === undefined) {
              return undefined; // target date
            }
          }
          if (pre.dateOffsetDays != null && pre.dateOffsetDays > 0) {
            so += pre.dateOffsetDays; // duration
          } else {
            so += .2;
          }
          if (so > startOffset) {
            startOffset = so;
          }
        }
      });
    }
    return startOffset;
  } // getStartOffsetPre

  /**
   * Cycle Depth First Search
   * @param currentNode test node
   * @param whiteNodeSet while list
   * @param grayIdSet gray list
   * @param blackIdSet black list
   * @return true if there is s cycle
   */
  private hasCycleDfs(currentNode: GraphNode, whiteNodeSet: Set<GraphNode>,
                      grayIdSet: Set<string>, blackIdSet: Set<string>): boolean {
    // move currentNode from white to gray
    whiteNodeSet.delete(currentNode);
    if (currentNode.sfId) {
      grayIdSet.add(currentNode.sfId);
    }
    //
    for (const dependentId of currentNode.dependentIds) {
      if (blackIdSet.has(dependentId)) {
        continue; // already explored
      }
      if (grayIdSet.has(dependentId)) {
        return true; // cycle found
      }
      const dependent = this.graph.getNode(dependentId);
      if (dependent && this.hasCycleDfs(dependent, whiteNodeSet, grayIdSet, blackIdSet)) {
        return true;
      }
    }
    // move currentNode from gray to black
    if (currentNode.sfId) {
      grayIdSet.delete(currentNode.sfId);
      blackIdSet.add(currentNode.sfId);
    }
    return false; // ok
  } // hasCycleDfs

  /**
   * Is the node a prerequisite
   * @param node the node
   * @param other compare
   * @return true if prerequisite
   */
  private isPrerequisite(node: GraphNode, other: GraphNode): boolean {
    if (other.prerequisiteIds) {
      for (const pid of other.prerequisiteIds) {
        if (pid === node.sfId) {
          return true;
        } else { // deep
          const otherDep = this.graph.getNode(pid);
          if (otherDep && this.isPrerequisite(node, otherDep)) {
            return true;
          }
        }
      }
    }
    return false;
  } // isPrerequisite

  /**
   * @param nodeA from
   * @param nodeB to
   * @param cmp ?
   * @param ii ?
   * @return sort node info
   */
  private toStringSort(nodeA: GraphNode, nodeB: GraphNode, cmp: string, ii: string): string {
    let info = nodeA.prefix() + '_' + nodeB.prefix() + ' ' + cmp;
    info += ': ' + nodeA.prefix() + ' ' + nodeA.startOffset;
    info += ' _ ' + nodeB.prefix() + ' ' + nodeB.startOffset;
    info += ' ' + ii;
    return info;
  } // toStringX

} // GraphUtil
