import { CheckList } from './check-list';
import { GraphNode } from './graph-node';

/* GenTsClass client: com.checklist4sf.domain.Graph */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Graph
 */
export class Graph {

  /**
   * label: CheckList
   */
  public checklist?: CheckList; // optional=null

  /**
   * label: Graph Height
   */
  public height?: number; // optional=null

  public info?: string; // optional=null

  /**
   * label: CheckList Items
   */
  public nodes: GraphNode[] = [];

  /**
   * label: CheckList SfId
   */
  public sfId?: string; // optional=null

  /**
   * label: Graph Width
   */
  public width?: number; // optional=null


  /**
   * Get Node with SfId
   */
  public getNode(sfId: string): GraphNode | undefined {
    for (const node of this.nodes) {
      if (node.sfId === sfId) {
        return node;
      }
    }
    return undefined;
  } // getNode

} // Graph
