import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Logger} from 'accorto';
import {GraphNode} from '../model/graph-node';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/**
 * Edit DataRecord
 *  this.editNode.record.values()
 * emits editorClose
 */
@Component({
  selector: 'c4sf-item-editor',
  templateUrl: './item-editor.component.html',
  styleUrls: ['./item-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemEditorComponent implements OnChanges, OnDestroy {

  /** Node to be edited */
  @Input() editNode?: GraphNode;

  /** Close/Save action */
  @Output() editorClose: EventEmitter<GraphNode> = new EventEmitter();

  /** Form Group */
  itemForm: FormGroup;

  private log: Logger = new Logger('ItemEditor');

  private destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Create
   * @param fb form builder
   */
  constructor(fb: FormBuilder) {
    this.itemForm = fb.group({
      name: [ '', Validators.required ],
      code: [ '' ],
      isActive: [ '' ],
      subject: [ '', Validators.required ],
      description: [ '' ],
      dateOffsetDays: [ '', [ Validators.required ] ],
      isWorkdays: [ '' ],
      isDependentsAllComplete: [ '' ],
      isRequired: [ '' ],
    });
    this.itemForm.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(sta => {
        if (this.itemForm.invalid) {
          console.log('form status', sta, this.itemForm.valid, this.itemForm.errors);
        }
        for (const key in this.itemForm.controls) {
          if (key) {
            const ctrl = this.itemForm.controls[ key ];
            if (ctrl.errors) {
              console.log('-', key, ctrl.errors);
            }
          }
        }
      });
  } // constructor

  getError(key: string): string | undefined {
    const fc = this.itemForm.controls[key];
    if (!fc) {
      this.log.warn('getError', 'NoFormControl=' + key);
    }
    if (fc && fc.errors != null) {
      for (const okey of Object.keys(fc.errors)) {
        const val = fc.errors[okey];
        if (okey === 'required') {
          return 'Please provide a value';
        } else if (okey === 'min') {
          return 'Minimum is ' + val.min + ' - entered ' + val.actual;
        }
      }
      return JSON.stringify(fc.errors);
    }
    return undefined;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.editNode) {
      this.log.debug('onChanges', this.editNode, changes)();
      // set dateOffsetDays
      if (this.editNode.item && !this.editNode.record) {
        this.editNode.record = this.editNode.item.asDataRecord();
      }
      if (this.editNode.record) {
        // current width
        this.editNode.record.changeMap.dateOffsetDays = String(this.editNode.dateOffsetDays);
        //
        this.itemForm.patchValue(this.editNode.record.values());
      }
    }
  } // ngOnChanges

  isError(key: string): boolean {
    const fc = this.itemForm.controls[ key ];
    if (!fc) {
      this.log.warn('isError', 'NoFormControl=' + key);
    }
    return fc && fc.errors != null;
  }

  onClose(): void {
    this.editorClose.emit(undefined);
  }

  /**
   * Save Item
   * - update DataRecord of GraphNode
   */
  onSave(): void {
    if (this.editNode?.record) {
      Object.assign(this.editNode.record.changeMap, this.itemForm.value);
      const changes = this.editNode.record.getChanges();
      const dateOffsetDays = this.editNode.record.value('dateOffsetDays');
      if (dateOffsetDays === undefined || dateOffsetDays === '') {
        this.editNode.dateOffsetDays = undefined;
      } else {
        this.editNode.dateOffsetDays = Number(dateOffsetDays);
      }
      // this.editNode.userInfo =

      this.log.info('onSave ' + this.itemForm.status, changes, this.editNode.record, this.itemForm.value)();
      this.editorClose.emit(this.editNode);
    }
  } // onSave

} // ItemEditorComponent
