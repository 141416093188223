import {GraphNode} from './graph-node';
import {Point2d} from 'accorto';

/**
 * Link
 */
export class GraphLink {

  public static readonly POINTER_WIDTH = 10;
  public static readonly POINTER_HEIGHT = 4;
  public label?: string;
  public description?: string;
  public isSelected: boolean = false;
  // The corner
  public corner: Point2d;
  // all nodes
  public nodes?: GraphNode[];
  // all corners
  public corners?: Point2d[];

  /**
   * Graph Link
   * @param startNode start
   * @param endNode end
   * @param index if link
   */
  constructor(public startNode: GraphNode,
              public endNode: GraphNode,
              public index: number) {
    this.isSelected = false;
    this.corner = this.linkCorner();
  }

  /**
   * Corner Point |_
   * @return corner Point
   */
  public linkCorner(): Point2d {
    const start = this.linkStart();
    const end = this.linkEnd();
    return new Point2d(start.x, end.y);
  }

  /**
   * Link End Point (left of End Node)
   * @return link end Point
   */
  public linkEnd(): Point2d {
    if (this.endNode) {
      const x = this.endNode.x;
      const y = this.endNode.y + (this.endNode.height / 2);
      return new Point2d(x, y);
    }
    return new Point2d(0, 0);
  }

  /**
   * Link Start Point (bottom of Start Node)
   * @return link start Point
   */
  public linkStart(): Point2d {
    if (this.startNode) {
      let x = this.startNode.x + (this.startNode.width / 2); // |
      if (x >= this.endNode.x - GraphLink.POINTER_WIDTH) {
        x = this.startNode.x + ((this.endNode.x - this.startNode.x) / 2);
      }
      const y = this.startNode.y + this.startNode.height; // -
      return new Point2d(x, y);
    }
    return new Point2d(0, 0);
  }

} // GraphLink
