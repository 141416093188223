import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AccortoModule } from 'accorto';
import { environment } from '../environments/environment';
import { reducers } from './reducers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EditorComponent } from './editor/editor.component';
import { GraphNodeComponent } from './graph-node/graph-node.component';
import { GraphLinkComponent } from './graph-link/graph-link.component';
import { ItemEditorComponent } from './item-editor/item-editor.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,

    AccortoModule.forRoot({
      server: environment.server,
      serverStage: environment.serverStage,
      production: environment.production,
      gaCode: 'UA-32129178-14',
      ts: environment.ts,
      productCode: 'Check4Sf',
      productTitle: 'Checklist for Salesforce'
    }),

    StoreModule.forRoot(reducers, {
      runtimeChecks:
        {
          strictStateImmutability: true, strictActionImmutability: true,
          strictStateSerializability: false, strictActionSerializability: false
        }
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({}) : [],
    //
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  declarations: [
    AppComponent,
    EditorComponent,
    GraphNodeComponent,
    GraphLinkComponent,
    ItemEditorComponent
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
