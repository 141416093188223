import {Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {DataRecord, Logger, Point2d} from 'accorto';
import {EditorService} from './editor.service';
import {GraphLink} from '../model/graph-link';
import {GraphNode} from '../model/graph-node';
import {Check4SfResponse} from '../model/check4-sf-response';
import {CheckList} from '../model/check-list';
import {GraphUtil} from './graph-util';
import {Graph} from '../model/graph';

/**
 * Checklist Editor
 * - called with sid/id from EditorServlet
 * -- handleLoad
 * - demo parameter: type=LIST, where=name='CheckListName'
 * - http://localhost:4200/?sfUn=jjanke@accorto.com.check&sfPw=xx&sfToken=BfCbYD484ZAn2TrwklDSZYHZ
 */
@Component({
  selector: 'c4sf-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorComponent
  implements OnInit, OnDestroy {

  message?: string = 'Loading ...';
  error?: string = undefined;

  // svg width
  width: number = 1024;
  // svg height
  height: number = 1024;

  nodes: GraphNode[] = [];
  links: GraphLink[] = [];
  graphUtil: GraphUtil = new GraphUtil(new Graph());

  // checklists for selection
  checklists: CheckList[] = [];

  // selected from node
  fromNode?: GraphNode = undefined;
  // selected link
  selectedLink?: GraphLink = undefined;
  // node to be edited
  editNode?: GraphNode = undefined;

  // busy flag
  busy: boolean = false;
  //
  checklistWhere?: string;

  /** Line From/To points */
  line1: Point2d = new Point2d(0, 0);
  line2: Point2d = new Point2d(0, 0);

  private routeParamSubscription?: Subscription;
  private log: Logger = new Logger('Editor');

  /**
   * Editor Component
   * @param service EditorService
   * @param route ActivatedRoute
   * @param router Router
   * @param elementRef ElementRef
   */
  constructor(private service: EditorService,
              private route: ActivatedRoute,
              private router: Router,
              private elementRef: ElementRef) {
    //  &#8286; | 8258
  }

  get showChecklists(): boolean {
    return this.checklists && this.checklists.length > 0;
  }

  /**
   * save disabled
   */
  get unchanged(): boolean {
    return this.graphUtil.isUnchanged();
  }

  /**
   * @return svg viewbox 0 0 width height
   */
  get viewBox(): string {
    return '0 0 ' + this.width + ' ' + this.height;
  }

  /**
   * Delete Item
   */
  doDeleteItem(): void {
    if (this.fromNode?.record) {
      const record: DataRecord = this.fromNode.record;
      const others: DataRecord[] = this.graphUtil.deleteNode(this.fromNode);
      this.busy = true;
      this.message = 'Updating ...';
      this.service.delete(record, others, this.graphUtil.graph.sfId)
        .subscribe(response => {
            this.handleLoad(response);
          },
          error => {
            this.handleError(error);
          }
        );
      this.fromNode = undefined;
    }
  } // doDeleteItem

  /** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Layout Graph
   * In:
   * - node.item.name|code
   * - node.dateOffsetDays
   * = node.prerequisiteIds
   * Out:
   * - node.title|startOffset
   * - node.x|y|width|height
   */
  doLayout(): void {
    const ref = this.elementRef.nativeElement as HTMLElement;
    const pageSize: ClientRect = ref.getBoundingClientRect();
    if (pageSize.width === 0) { // elementRef not initialized
      setTimeout(() => {
        this.doLayout();
      }, 500);
    }
    this.width = 1024;
    if (pageSize.width > 0) {
      if (pageSize.width < 480) {
        this.width = 480;
      } else {
        this.width = pageSize.width - (2 * 13); // slds-card + slds-p-around_small
      }
    }
    this.height = 1024;
    if (pageSize.height > 0) {
      this.height = pageSize.height - (2 * 13) // slds-card + slds-p-around_small
        - (2 * 17) + 125; // slds-page-header + header content
      if (this.height < 1024) {
        this.height = 1024;
      }
    }
    //
    this.selectedLink = undefined;
    this.fromNode = undefined;

    this.error = this.graphUtil.calculate(); // cycles
    if (this.error) {
      return;
    }
    this.nodes = this.graphUtil.graph.nodes;
    // x/y layout
    this.graphUtil.layout(this.width, this.height, pageSize);
    // create links
    this.links = this.graphUtil.createLinks();
  } // doLayout

  /**
   * Delete Link
   */
  doLinkDeleteClick(): void {
    if (this.selectedLink) {
      this.graphUtil.deleteLink(this.selectedLink);
      this.message = 'Deleted Link: ' + this.selectedLink.label;
      this.doLayout();
    }
  } // onLinkDeleteClick

  /**
   * New Item
   * @see hideItemEditor
   */
  doNewItem(): void {
    this.log.debug('doNewItem', this.graphUtil.getChecklistSfId())();
    if (this.graphUtil.getChecklistSfId()) {
      const node = this.graphUtil.newNode();
      this.showItemEditor(node); // save: hideItemEditor
    }
  }

  /**
   * Refresh / requery
   */
  doRefresh(): void {
    this.log.info('doRefresh')();
    this.busy = true;
    this.message = 'Loading ...';
    this.service.query(undefined, this.checklistWhere)
      .subscribe(response => {
          this.handleLoad(response);
        },
        error => {
          this.handleError(error);
        }
      );
  } // doRefresh

  /**
   * Save
   */
  doSave(): void {
    this.busy = true;
    this.message = 'Saving ...';
    this.fromNode = undefined;
    //
    const records: DataRecord[] = this.graphUtil.changedRecords();
    this.log.info('doSave', records, this.graphUtil.graph.sfId)();
    if (records.length > 0) {
      this.busy = true;
      this.message = 'Saving ...';
      this.service.saveRecords(records, this.graphUtil.graph.sfId)
        .subscribe(response => {
            this.handleLoad(response);
          },
          error => {
            this.handleError(error);
          }
        );
      // this.ga.event('editor', 'save', 'save', this.graph.nodes.length);
    }
  } // doSave

  /**
   * Error Handler
   * @param error the error
   */
  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      this.error = (error as HttpErrorResponse).message;
    } else {
      this.error = JSON.stringify(error);
    }
    this.message = 'Communication Error';
    this.log.error('handleError', this.message, error)();
    this.busy = false;
  } // handleError

  /**
   * Load Handler
   * @param response HttpResponse<ClientResponse>
   */
  handleLoad(response: Check4SfResponse): void {
    this.log.debug('handleLoad', response)();
    this.message = response.message;
    this.error = response.error;
    // checklist graph
    if (response.graph) {
      this.graphUtil = new GraphUtil(response.graph); // set
      this.message = response.message + ' (' + response.graph.info + ')';
      if (this.error) {
        this.width = this.width - (2 * 13); // slds-card + slds-p-around_small
      } else {
        this.doLayout();
      }
      // if (window.performance) {
      //  const timeSincePageLoad = Math.round(performance.now());
      //  this.ga.timing('editor', 'load', timeSincePageLoad);
      // }
    } // graph
    // checklist items
    if (response.checklists) {
      this.checklists = response.checklists;
    }
    this.busy = false;
  } // handleLoad

  /**
   * Hide Editor and save new
   * @param node changed node
   * @see showItemEditor
   */
  hideItemEditor(node: GraphNode): void {
    this.log.debug('hideItemEditor', node, this.graphUtil.graph.sfId)();
    if (node && node.record) {
      this.graphUtil.addNode(node);
      this.busy = true;
      this.message = 'Updating ...';
      this.service.saveRecord(node.record, this.graphUtil.graph.sfId)
        .subscribe(
          response => {
            this.handleLoad(response);
          },
          error => {
            this.handleError(error);
          }
        );
    }
    this.editNode = undefined; // hide
  } // hideItemEditor

  /**
   * Lifecycle Destroy
   */
  ngOnDestroy(): void {
    if (this.routeParamSubscription) {
      this.routeParamSubscription.unsubscribe();
    }
    this.routeParamSubscription = undefined;
  } // ngOnDestroy

  /**
   * Lifecycle Initialize
   */
  ngOnInit(): void {
    // http://localhost:4200/#/editor?sid=abcefd
    if (this.routeParamSubscription) {
      this.routeParamSubscription.unsubscribe();
    }
    this.routeParamSubscription = this.route.queryParams.subscribe(params => {
      const sid = params.sid;
      const id = params.id;
      this.log.info('ngOnInit', 'sid=' + sid + ' id=' + id)();
      if (sid || id) { // initial call
        this.service.querySid(sid, id)
          .subscribe(response => {
              this.handleLoad(response);
            },
            error => {
              this.handleError(error);
            }
          );
      } else {
        if (sid === undefined && id === undefined) { // demo
          this.checklistWhere = 'Name LIKE \'Opportunity Demo\'';
        }
        this.doRefresh(); // calls handleLoad
      }
    });
  } // ngOnInit

  /**
   * Load Selected Checklist
   */
  onChecklistChange(event: Event): void {
    const select = event.target as HTMLSelectElement;
    const sfId = select.value;
    this.log.info('onChecklistChange', sfId)();
    this.busy = true;
    this.message = 'Loading Checklist...';
    this.service.query(sfId)
      .subscribe(response => {
          this.handleLoad(response);
        },
        error => {
          this.handleError(error);
        });
  } // onChecklistChange

  /** ** ** ** **
   * Click on Link
   * @param link selected link
   */
  onLinkClick(link: GraphLink): void {
    // console.log('Editor.onLinkClick', link);
    link.isSelected = !link.isSelected;

    if (link.isSelected) {
      if (this.fromNode) {
        this.fromNode.isSelected = false;
        this.fromNode = undefined;
      }
      this.selectedLink = link;
      this.links.forEach((l) => {
        if (l !== link) {
          l.isSelected = false;
        }
      });
    } else {
      this.selectedLink = undefined;
    }
    this.message = undefined;
  } // onLinkClick

  /**
   * Draw Link Line - causes flicker and target click stability
   * on top svg: (mousemove)="onMouseMove($event)"
   */
  onMouseMove(event: MouseEvent): void {
    if (this.fromNode) {
      // console.log(event.offsetX + '/' + event.offsetY + ' - ' + event.clientX + '/' + event.clientY);
      this.line2.x = event.offsetX;
      this.line2.y = event.offsetY;
    }
  }

  /** ** ** ** **
   * Click in Node
   * @param node the clicked node
   * @param event the click event
   */
  onNodeClick(node: GraphNode, event: MouseEvent): void {
    this.log.debug('onNodeClick', node.title, event)();
    event.stopPropagation();
    node.isSelected = !node.isSelected;
    if (node.isSelected) {
      if (this.selectedLink) { // remove link
        this.selectedLink.isSelected = false;
        this.selectedLink = undefined;
      }
      if (this.fromNode) {
        if (node.sfId === this.fromNode.sfId) {
          return; // same node
        }
        // check if we can create link
        if (node.prerequisiteIds && this.fromNode.sfId) {
          const index = node.prerequisiteIds.indexOf(this.fromNode.sfId);
          if (index >= 0) { // already exists
            node.isSelected = false;
            this.fromNode.isSelected = false;
            this.fromNode = undefined;
            this.message = 'Link already exists';
            return;
          }
        }
        node.isSelected = false;
        this.message = this.graphUtil.hasCycle(this.fromNode.sfId, node.sfId);
        if (this.message) {
          return;
        }
        // add
        this.message = this.graphUtil.addLink(this.fromNode, node);
        this.doLayout();
      } else { // new starting point
        this.fromNode = node;
        this.message = undefined;
      }
    } else { // not selected
      if (this.fromNode && this.fromNode.sfId === node.sfId) {
        this.fromNode = undefined; // same node
        // blur comes here
      }
    }
    // draw link line
    if (this.fromNode) {
      this.line1.x = event.offsetX; // from
      this.line1.y = event.offsetY;
      this.line2.x = event.offsetX;
      this.line2.y = event.offsetY;
    }
  } // onNodeClick

  /**
   * Key Up on Node - check +/- to change duration
   * @param node active node
   * @param event keyboard event
   */
  onNodeKey(node: GraphNode, event: KeyboardEvent): void {
    const key = event.key;
    this.log.debug('onNodeKey', key, node.title)();

    const oldFromNode = this.fromNode;
    const oldSelected = node.isSelected;
    const oldDuration = node.dateOffsetDays;
    if (key === '+' || key === 'ArrowRight') {
      this.graphUtil.changeNodeDuration(node, true);
      this.doLayout();
    } else if ((key === '-' || key === 'ArrowLeft')) {
      this.graphUtil.changeNodeDuration(node, false);
      this.doLayout();
    }
    // keep previous selection state
    if (node.dateOffsetDays !== oldDuration) {
      this.fromNode = oldFromNode;
      if (this.fromNode) {
        this.fromNode.isSelected = oldSelected;
      }
    } else if (key === 'Enter') { // edit
      this.showItemEditor(node);
    } else if (key === 'Escape') {
      this.fromNode = undefined;
      // node.isSelected = false;
      this.graphUtil.unselectNodes();
      //
      const target = event.target as HTMLElement;
      if (target) {
        target.blur();
      }
    }
  } // onNodeKey

  /**
   * Show Item Editor (double click)
   * @param node node to be edited
   * @see hideItemEditor
   */
  showItemEditor(node: GraphNode): void {
    node.isSelected = false;
    this.editNode = node;
    this.fromNode = undefined;
  }

} // EditorComponent
