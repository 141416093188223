import { CRequest } from 'accorto';
import { DataRecord } from 'accorto';

/* GenTsClass client: com.checklist4sf.domain.Check4SfRequest */
/* tslint:disable max-line-length no-inferrable-types */

export enum Check4SfType {
  DELETE = 'DELETE',
  LIST = 'LIST',
  QUERY = 'QUERY',
  SAVE = 'SAVE',
  UPSERT = 'UPSERT'
}

/**
 * extends: CRequest
 * name: Check4SfRequest
 */
export class Check4SfRequest extends CRequest {

  /**
   * Insert, Update, Delete
   * label: CheckList
   */
  public checklist?: DataRecord; // optional=null

  /**
   * Item Query
   * label: CheckList Id
   */
  public checklistId?: string; // optional=null

  /**
   * Insert, Update, Delete
   * label: CheckList Item
   */
  public checklistItem?: DataRecord; // optional=null

  /**
   * List
   * label: CheckList Where
   */
  public checklistWhere?: string; // optional=null

  /**
   * Save (relationships)
   * label: Changes
   */
  public checklistItems: DataRecord[] = [];

  /**
   * label: Request Type
   */
  public type?: Check4SfType; // optional=null

} // Check4SfRequest
