import { TenantUserI } from 'accorto';
import { TenantI } from 'accorto';
import { CheckListI } from './check-list-i';

/* GenTsInterface domain: CheckListItem */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CheckListItem
 */
export interface CheckListItemI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk CheckListI
   * Parent
   * label: CheckList
   */
  checkList?: CheckListI;

  /**
   * parent fk CheckListI key
   * Parent
   * label: CheckList
   */
  checkListId?: number|string;

  /**
   * Parent SfId
   * label: CheckList SfId
   */
  checkListSfId: string;

  /**
   * Optional Code, e.g used for sorting
   * label: Code
   */
  code?: string;

  /**
   * Subject for the new Task or Event - you can reference target record values e.g. {!Name}
   * label: Subject
   */
  subject: string;

  /**
   * Due Date offset in days (0=no Due Date) from creating the checklist (Duration)
   * - If there is a Prerequisite/Dependent, it is after that task is completed.
   * label: Date Offset Days
   */
  dateOffsetDays?: number;

  /**
   * If selected, skip Weekends and Holidays - e.g. Friday + 2 days = Tuesday (instead of Sunday).
   * Define Holidays as All Day (recurring currently not supported).
   * label: Use Workdays
   */
  isWorkdays: boolean;

  /**
   * If selected, to start the task, ALL prior Tasks need to be completed (the tasks/Items this item/task depends on).
   * Otherwise, if ANY of the previous Tasks is completed, this Item/Task Status is set from Waiting to Not Started.
   * label: All Complete
   */
  isDependentsAllComplete: boolean;

  /**
   * The Task is required - i.e. the user cannot choose not to create it
   * label: Required
   */
  isRequired?: boolean;

  /**
   * Task Priority (default Normal) - validity checked when entered
   * label: Priority
   */
  priority?: string;

  /**
   * Record Type of the Task record to be created (if used, empty for default)
   * label: Record Type
   */
  recordType?: string;

  /**
   * Task Type (e.g. Call, Meeting, Email, Other, ...) - validity checked when entered
   * label: Type
   */
  type?: string;

  /**
   * Optional Default Assigned To User
   * label: Assigned User
   */
  userSfId?: string;

  /**
   * Reference of the base record (to add Check:ist to) to find user to assign the task,
   * e.g. Owner (or OwnerId)
   * - or a lookup to the user (e.g. myField__c, accorto__Resource__r.accorto__User__c)
   * label: Dynamically Assigned User
   */
  userDynamic?: string;

  /**
   * Prerequisite item/task to be completed - The Prerequisite needs to be completed before the Status of this item/task is set from Waiting to Not Started
   * label: Prerequisite
   */
  prerequisiteSfId?: string;

  /**
   * Internal list of prerequisite item Ids
   * label: Prerequisite Id List
   */
  prerequisiteIdList?: string;

  /**
   * The task/item is Dependent on this task/item to be complete. Depending on all/any dependent completion, the status of the Dependent task/item is set from Waiting to Not Started
   * label: Dependent
   */
  dependentSfId?: string;

  /**
   * label: Last Modified Date
   */
  lastModifiedDate?: number;

} // CheckListItem

/** CheckListItem Definition */
export const CheckListItemD = {
  recordTypeName: 'CheckListItem',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  checkList: {
    n: 'checkList',
    t: 'CheckListI'
  },
  checkListId: {
    n: 'checkListId',
    t: 'number|string'
  },
  checkListSfId: {
    n: 'checkListSfId',
    t: 'string'
  },
  code: {
    n: 'code',
    t: 'string'
  },
  subject: {
    n: 'subject',
    t: 'string'
  },
  dateOffsetDays: {
    n: 'dateOffsetDays',
    t: 'number'
  },
  isWorkdays: {
    n: 'isWorkdays',
    t: 'boolean'
  },
  isDependentsAllComplete: {
    n: 'isDependentsAllComplete',
    t: 'boolean'
  },
  isRequired: {
    n: 'isRequired',
    t: 'boolean',
    d: false
  },
  priority: {
    n: 'priority',
    t: 'string'
  },
  recordType: {
    n: 'recordType',
    t: 'string'
  },
  type: {
    n: 'type',
    t: 'string'
  },
  userSfId: {
    n: 'userSfId',
    t: 'string'
  },
  userDynamic: {
    n: 'userDynamic',
    t: 'string'
  },
  prerequisiteSfId: {
    n: 'prerequisiteSfId',
    t: 'string'
  },
  prerequisiteIdList: {
    n: 'prerequisiteIdList',
    t: 'string'
  },
  dependentSfId: {
    n: 'dependentSfId',
    t: 'string'
  },
  lastModifiedDate: {
    n: 'lastModifiedDate',
    t: 'number'
  },
}; // CheckListItem
