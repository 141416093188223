import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Point2d} from 'accorto';
import {GraphLink} from '../model/graph-link';

/**
 * Graph Link
 * - attribute selector
 */

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[c4sf-graph-link]',
  templateUrl: './graph-link.component.html',
  styleUrls: ['./graph-link.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GraphLinkComponent implements OnChanges {

  @Input() link?: GraphLink;
  @Input() id?: string;

  /** polyline points */
  points: string = '';

  constructor() {
  }

  get title(): string {
    if (this.link?.label) {
      return this.link.label;
    }
    return '';
  }

  get color(): string {
    return this.link && this.link.isSelected ? 'red' : 'black';
  }

  /**
   * Layout
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(this.link);
    if (!this.link) {
      return;
    }
    const fromPoint: Point2d = this.link.linkStart();
    const toPoint: Point2d = this.link.linkEnd();
    //
    const corner: Point2d = this.link.corner;
    if (this.link.corners) {
      for (let i = 0; i < this.link.corners.length; i++) {
        if (this.link.index > i) {
          const otherCorner: Point2d = this.link.corners[ i ];
          if (corner.isSame(otherCorner)) {
            // console.debug('LinkCorner ' + this.link.index + '(' + this.link.label + ') ' + i, corner, otherCorner);
            fromPoint.addX(10);
            corner.addX(10);
            this.link.corners[ this.link.index ].addX(10); //
          }
        }
      }
    }

    // |_
    this.points = fromPoint.toString();       // |
    this.points += ' ' + corner.toString();
    this.points += ' ' + toPoint.toString();  // _

    // marker-end="url(#triangle)"
    const t1 = new Point2d(toPoint.x - GraphLink.POINTER_WIDTH, toPoint.y - GraphLink.POINTER_HEIGHT);
    const t2 = new Point2d(toPoint.x - GraphLink.POINTER_WIDTH, toPoint.y + GraphLink.POINTER_HEIGHT);
    this.points += ' ' + t1.toString();  // \
    this.points += ' ' + t2.toString();  // /
    this.points += ' ' + toPoint.toString();
    // console.log('GraphLink ' + this.link.label + ' from=' + fromPoint + ' to=' + toPoint + ' -> ' + this.points);
  } // ngOnChanges

} // GraphLinkComponent
