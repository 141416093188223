import { TenantUser } from 'accorto';
import { Tenant } from 'accorto';
import { CheckList } from './check-list';
import { DataRecordI } from 'accorto';
import { CheckListItemI, CheckListItemD } from './check-list-item-i';
import { DataRecord } from 'accorto';
import { DataRecordF } from 'accorto';

/* GenTsClass domain: CheckListItem */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CheckListItem
 */
export class CheckListItem {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * parent fk CheckList
   * Parent
   * label: CheckList
   */
  public checkList?: CheckList; // optional=true

  public checkListId?: number; // optional=true

  /**
   * Parent SfId
   * label: CheckList SfId
   */
  public checkListSfId: string = ''; // default

  /**
   * Optional Code, e.g used for sorting
   * label: Code
   */
  public code?: string; // optional=true

  /**
   * Subject for the new Task or Event - you can reference target record values e.g. {!Name}
   * label: Subject
   */
  public subject: string = ''; // default

  /**
   * Due Date offset in days (0=no Due Date) from creating the checklist (Duration)
   * - If there is a Prerequisite/Dependent, it is after that task is completed.
   * label: Date Offset Days
   */
  public dateOffsetDays?: number; // optional=true

  /**
   * If selected, skip Weekends and Holidays - e.g. Friday + 2 days = Tuesday (instead of Sunday).
   * Define Holidays as All Day (recurring currently not supported).
   * label: Use Workdays
   */
  public isWorkdays: boolean = false; // default

  /**
   * If selected, to start the task, ALL prior Tasks need to be completed (the tasks/Items this item/task depends on).
   * Otherwise, if ANY of the previous Tasks is completed, this Item/Task Status is set from Waiting to Not Started.
   * label: All Complete
   */
  public isDependentsAllComplete: boolean = false; // default

  /**
   * The Task is required - i.e. the user cannot choose not to create it
   * label: Required
   */
  public isRequired: boolean = false;

  /**
   * Task Priority (default Normal) - validity checked when entered
   * label: Priority
   */
  public priority?: string; // optional=true

  /**
   * Record Type of the Task record to be created (if used, empty for default)
   * label: Record Type
   */
  public recordType?: string; // optional=true

  /**
   * Task Type (e.g. Call, Meeting, Email, Other, ...) - validity checked when entered
   * label: Type
   */
  public type?: string; // optional=true

  /**
   * Optional Default Assigned To User
   * label: Assigned User
   */
  public userSfId?: string; // optional=true

  /**
   * Reference of the base record (to add Check:ist to) to find user to assign the task,
   * e.g. Owner (or OwnerId)
   * - or a lookup to the user (e.g. myField__c, accorto__Resource__r.accorto__User__c)
   * label: Dynamically Assigned User
   */
  public userDynamic?: string; // optional=true

  /**
   * Prerequisite item/task to be completed - The Prerequisite needs to be completed before the Status of this item/task is set from Waiting to Not Started
   * label: Prerequisite
   */
  public prerequisiteSfId?: string; // optional=true

  /**
   * Internal list of prerequisite item Ids
   * label: Prerequisite Id List
   */
  public prerequisiteIdList?: string; // optional=true

  /**
   * The task/item is Dependent on this task/item to be complete. Depending on all/any dependent completion, the status of the Dependent task/item is set from Waiting to Not Started
   * label: Dependent
   */
  public dependentSfId?: string; // optional=true

  /**
   * label: Last Modified Date
   */
  public lastModifiedDate?: number; // optional=true


  /**
   * @return CheckListItem record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'CheckListItem';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): CheckListItem {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, CheckListItemD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, CheckListItemD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: CheckListItemI): CheckListItem {
    Object.keys(CheckListItemD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // CheckListItem
