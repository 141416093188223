import { TenantUserI } from 'accorto';
import { TenantI } from 'accorto';

/* GenTsInterface domain: CheckList */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CheckList
 */
export interface CheckListI {

  /**
   * Optional Checklist Code, e.g. for sorting
   * label: Code
   */
  code?: string;

  /**
   * If selected, you can add checklist tasks just once per record
   * label: Apply only once
   */
  isApplyOnlyOnce: boolean;

  /**
   * The checklist can only be used manually - not in Processes (Process Builder, Trigger, Apex, Flows)
   * label: Manual Only
   */
  isManualOnly: boolean;

  /**
   * label: Last Modified Date
   */
  lastModifiedDate?: number;

  /**
   * Use this checklist only if the expression is valid (basically where clause) - requires Only For Object
   * - example for Account: Rating = 'Warm'
   * label: Only if valid
   */
  onlyIfValid?: string;

  /**
   * Restrict CheckList to specific objects, e.g Account, MyObject__c
   * label: Only for Object
   */
  onlyforObject?: string;

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

} // CheckList

/** CheckList Definition */
export const CheckListD = {
  recordTypeName: 'CheckList',
  code: {
    n: 'code',
    t: 'string'
  },
  isApplyOnlyOnce: {
    n: 'isApplyOnlyOnce',
    t: 'boolean'
  },
  isManualOnly: {
    n: 'isManualOnly',
    t: 'boolean'
  },
  lastModifiedDate: {
    n: 'lastModifiedDate',
    t: 'number'
  },
  onlyIfValid: {
    n: 'onlyIfValid',
    t: 'string'
  },
  onlyforObject: {
    n: 'onlyforObject',
    t: 'string'
  },
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
}; // CheckList
