import { CheckListItem } from './check-list-item';
import { DataRecord } from 'accorto';

/* GenTsClass client: com.checklist4sf.domain.GraphNode */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: GraphNode
 */
export class GraphNode {

  /**
   * local changes
   * label: Date Offset Days (width)
   */
  public dateOffsetDays?: number; // optional=null

  /**
   * label: Dependent List
   */
  public dependentIds: string[] = [];

  /**
   * client only
   * label: Height
   */
  public height: number = 0;

  /**
   * client only
   * label: Selected
   */
  public isSelected: boolean = false;

  /**
   * client only
   * label: Set of dependent ids
   */
  public dependentSet: Set<string> = new Set<string>();

  /**
   * local changes
   * label: Prerequisites (SfIds)
   */
  public prerequisiteIds: string[] = [];

  /**
   * changes
   * label: Data Record
   */
  public record?: DataRecord; // optional=null

  /**
   * label: CheckList Item
   */
  public item?: CheckListItem; // optional=null

  /**
   * client only relative start
   * label: Start Offset
   */
  public startOffset: number = 0;

  /**
   * client only
   * label: Set of prerequisite ids
   */
  public prerequisiteSet: Set<string> = new Set<string>();

  /**
   * e.g. Record Owner
   * label: User Info
   */
  public userInfo?: string; // optional=null

  /**
   * client only
   * label: Width
   */
  public width: number = 0;

  /**
   * client only
   * label: X pos
   */
  public x: number = 0;

  /**
   * client only
   * label: Y pos
   */
  public y: number = 0;

  /**
   * label: Item SfId
   */
  public sfId?: string; // optional=null

  /**
   * label: Debug Title
   */
  public title?: string; // optional=null


  /**
   * code: name
   */
  public prefix(): string {
    if (this.record) {
      const info = this.record.value('code');
      if (info) {
        return info + ': ' + this.record.value('name');
      }
      return this.record.value('name') ?? toString();
    }
    return toString();
  } // prefix


  /**
   * code: name
   */
  public toString(): string {
    if (this.item) {
      const info = this.item.code;
      if (info) {
        return info + ': ' + this.item.name;
      }
      return this.item.name;
    }
    return '-';
  } // toString


  /**
   * code: name start=.. duration=..
   */
  public toStringX(): string {
    let info = this.sfId
      + ' ' + this.prefix()
      + ' start=' + this.startOffset + ' dur=' + this.dateOffsetDays;
    if (this.prerequisiteIds) {
      for (const pid of this.prerequisiteIds) {
        info += ' pre:' + pid;
      }
    }
    if (this.dependentIds) {
      for (const did of this.dependentIds) {
        info += ' dep:' + did;
      }
    }
    return info;
  } // toStringX

} // GraphNode
