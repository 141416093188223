import { Graph } from './graph';
import { Check4SfResponse } from './check4-sf-response';
import { Checklist4sfUtil } from './checklist4sf-util';
import { GraphNode } from './graph-node';
import { Check4SfRequest } from './check4-sf-request';
import { AccortoCUtil } from 'accorto';

/* GenTsClass utility: Checklist4sfCUtil */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * Client Generated Utility for com.checklist4sf.domain
 */
export class Checklist4sfCUtil extends Checklist4sfUtil {


  /**
   * Convert json to Check4SfRequest
   * @return populated Check4SfRequest
   * @param json json info
   */
  static createCheck4SfRequest(json: any): Check4SfRequest {
    const entity: Check4SfRequest = new Check4SfRequest();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'checklist') {
        entity.checklist = AccortoCUtil.createDataRecord(value); // cl
      } else if (key === 'checklistId') {
        entity.checklistId = value;
      } else if (key === 'checklistItem') {
        entity.checklistItem = AccortoCUtil.createDataRecord(value); // cl
      } else if (key === 'checklistWhere') {
        entity.checklistWhere = value;
      } else if (key === 'checklistItems') {
        entity.checklistItems = [];
        for (const vv of value) {
          entity.checklistItems.push(AccortoCUtil.createDataRecord(vv));
        }
      } else if (key === 'type') {
        entity.type = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'params') {
        entity.params = {};
        for (const vv of Object.keys(value)) {
          entity.params[vv] = value[vv];
        }
      } else if (key === 'referrer') {
        entity.referrer = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'rid') {
        entity.rid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'timeMs') {
        entity.timeMs = value;
      } else if (key === 'timeString') {
        entity.timeString = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetMinutes') {
        entity.timezoneOffsetMinutes = value;
      }
    } // for loop
    return entity;
  } // createCheck4SfRequest


  /**
   * Convert json to Check4SfResponse
   * @return populated Check4SfResponse
   * @param json json info
   */
  static createCheck4SfResponse(json: any): Check4SfResponse {
    const entity: Check4SfResponse = new Check4SfResponse();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'checklists') {
        entity.checklists = [];
        for (const vv of value) {
          entity.checklists.push(this.createCheckList(vv));
        }
      } else if (key === 'graph') {
        entity.graph = this.createGraph(value); // cl
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'savedId') {
        entity.savedId = value;
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createCheck4SfResponse


  /**
   * Convert json to Graph
   * @return populated Graph
   * @param json json info
   */
  static createGraph(json: any): Graph {
    const entity: Graph = new Graph();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'checklist') {
        entity.checklist = this.createCheckList(value); // cl
      } else if (key === 'info') {
        entity.info = value;
      } else if (key === 'nodes') {
        entity.nodes = [];
        for (const vv of value) {
          entity.nodes.push(this.createGraphNode(vv));
        }
      } else if (key === 'sfId') {
        entity.sfId = value;
      }
    } // for loop
    return entity;
  } // createGraph


  /**
   * Convert json to GraphNode
   * @return populated GraphNode
   * @param json json info
   */
  static createGraphNode(json: any): GraphNode {
    const entity: GraphNode = new GraphNode();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'dateOffsetDays') {
        entity.dateOffsetDays = value;
      } else if (key === 'dependentIds') {
        entity.dependentIds = [];
        for (const vv of value) {
          entity.dependentIds.push(vv);
        }
      } else if (key === 'prerequisiteIds') {
        entity.prerequisiteIds = [];
        for (const vv of value) {
          entity.prerequisiteIds.push(vv);
        }
      } else if (key === 'record') {
        entity.record = AccortoCUtil.createDataRecord(value); // cl
      } else if (key === 'item') {
        entity.item = this.createCheckListItem(value); // cl
      } else if (key === 'userInfo') {
        entity.userInfo = value;
      } else if (key === 'sfId') {
        entity.sfId = value;
      }
    } // for loop
    return entity;
  } // createGraphNode

} // Checklist4sfCUtil
