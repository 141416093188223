import {Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {GraphNode} from '../model/graph-node';

/**
 * Graph Node (rectangle)
 * - attribute selector
 */

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[c4sf-graph-node]',
  templateUrl: './graph-node.component.html',
  styleUrls: [ './graph-node.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class GraphNodeComponent {

  @Input() node: GraphNode = new GraphNode();

  labelHeight: number = 18;

  @ViewChild('theText', {static: false}) textRef?: ElementRef;

  /**
   * Graph Node
   * https://teropa.info/blog/2016/12/12/graphics-in-angular-2.html
   */
  constructor() {
  }

  // rectangle
  get x(): number {
    return this.node.x;
  }

  // rectangle
  get y(): number {
    return this.node.y;
  }

  // rectangle
  get height(): number {
    return this.node.height;
  }

  // rectangle
  get width(): number {
    return this.node.width;
  }

  get color(): string {
    return this.node.isSelected ? 'red' : 'blue';
  }


  get label(): string {
    return this.node.prefix();
  }

  get labelX(): number {
    return this.node.x + 8;
  }

  get labelY(): number {
    return this.node.y + this.labelHeight;
  }

  get labelYtop(): number {
    return this.node.y + 4;
  }

  get labelWidth(): number {
    let chars = 2;
    if (this.label) {
      chars += this.label.length;
    }
    if (this.userInfo) {
      chars += this.userInfo.length;
    }
    return chars * 6;
    // return this.node.width;
  }


  get title(): string {
    return this.node.title ?? '';
  }

  get userInfo(): string {
    return this.node.userInfo ?? '';
  }


  get duration(): string {
    if (this.node.dateOffsetDays) {
      return this.node.dateOffsetDays.toString();
    }
    return '-';
  }

  get durationX(): number {
    return this.node.x + this.node.width - 8;
  }

  get durationY(): number {
    return this.node.y + this.node.height - 8;
  }

  // https://dev.w3.org/html5/html-author/charref
  // &xrArr;&marker; ⟹▮ &#x027F9;&#x025AE;
  get showAllComplete(): boolean {
    if (this.node.record
      && this.node.prerequisiteIds && this.node.prerequisiteIds.length > 1) {
      return this.node.record.value('isDependentsAllComplete') === 'true';
    }
    return false;
  }

  // &xrArr;&Rang; ⟹⟫ &#x027F9;&#x027EB;
  get showFirstComplete(): boolean {
    if (this.node.record
      && this.node.prerequisiteIds && this.node.prerequisiteIds.length > 1) {
      return this.node.record.value('isDependentsAllComplete') !== 'true';
    }
    return false;
  }

  // count of prerequisites
  get countPrerequisites(): string {
    if (this.node.prerequisiteIds) {
      return '(' + this.node.prerequisiteIds.length + ')';
    }
    return '';
  }


} // GraphNodeComponent
