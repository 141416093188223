import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AccortoService, DataRecord, Logger} from 'accorto';
import {Check4SfRequest, Check4SfType} from '../model/check4-sf-request';
import {Check4SfResponse} from '../model/check4-sf-response';
import {map, tap} from 'rxjs/operators';
import {Checklist4sfCUtil} from '../model/checklist4sf-c-util';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  // session
  private sid?: string;
  // checklistId
  private id?: string;

  private log: Logger = new Logger('EditorService');

  /**
   * Editor Service
   */
  constructor(private config: AccortoService,
              private http: HttpClient) {
    // console.log('service', window.location);
  }

  /**
   * Delete item
   * @param record record to be deleted
   * @param others remaining changed records
   * @param id checklist id
   */
  delete(record: DataRecord, others: DataRecord[], id?: string): Observable<Check4SfResponse> {
    if (id) {
      this.id = id;
    }
    const request = new Check4SfRequest();
    this.config.setCRequest(request);
    request.sid = this.sid;
    request.type = Check4SfType.DELETE;
    request.checklistItem = record;
    request.checklistItems = others;
    request.checklistId = this.id;
    return this.send(request);
  }

  /**
   * Query
   */
  query(id?: string, checklistWhere?: string): Observable<Check4SfResponse> {
    if (id) {
      this.id = id;
    }
    const request = new Check4SfRequest();
    this.config.setCRequest(request);
    request.sid = this.sid;
    request.checklistId = this.id;
    request.checklistWhere = checklistWhere;
    request.type = Check4SfType.QUERY;
    request.params = this.config.env; // copy parameters
    return this.send(request);
  } // query

  /**
   * Set SID and Query
   * @param sid session id
   * @param id initial checklist id
   */
  querySid(sid: string, id: string): Observable<Check4SfResponse> {
    this.sid = sid;
    return this.query(id);
  }

  /**
   * Save/update single checklist item
   * @param record DataRecord
   * @param id checklist id to requery
   */
  saveRecord(record: DataRecord, id?: string): Observable<Check4SfResponse> {
    if (id) {
      this.id = id;
    }
    const request = new Check4SfRequest();
    this.config.setCRequest(request);
    request.sid = this.sid;
    request.type = Check4SfType.UPSERT;
    request.checklistItem = record;
    request.checklistId = this.id;
    return this.send(request);
  }

  /**
   * Save/update all (links)
   * @param records DataRecord[]
   * @param id checklist id to requery
   */
  saveRecords(records: DataRecord[], id?: string): Observable<Check4SfResponse> {
    if (id) {
      this.id = id;
    }
    const request = new Check4SfRequest();
    this.config.setCRequest(request);
    request.sid = this.sid;
    request.type = Check4SfType.SAVE;
    request.checklistItems = records;
    request.checklistId = this.id;
    return this.send(request);
  }

  /**
   * Send request
   */
  send(request: Check4SfRequest): Observable<Check4SfResponse> {
    const url = this.config.server + '/c4sf';
    this.log.debug('send ' + url, request)();
    const body = JSON.stringify(request);
    return this.http.post<Check4SfResponse>(url, body, {})
      .pipe(
        tap(respone => {
          this.log.debug('send.received', respone)();
          if (respone.sid) {
            this.sid = respone.sid;
          }
        }),
        map(response => Checklist4sfCUtil.createCheck4SfResponse(response))
      );
  } // send

} // EditorService
