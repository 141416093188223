import { CheckListItem } from './check-list-item';
import { CheckList } from './check-list';
import { DataRecord } from 'accorto';
import { AccortoUtil } from 'accorto';

/* GenTsClass utility: Checklist4sfUtil */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * Domain Generated Utility for com.checklist4sf.domain
 */
export class Checklist4sfUtil extends AccortoUtil {


  /**
   * Convert DataRecord to CheckList
   * @return populated CheckList
   * @param dr data record
   */
  static convertCheckList(dr: DataRecord): CheckList {
    const result = this.createCheckList(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      Object.assign(result, dr.changeMap);
    }
    return result;
  } // convertCheckList


  /**
   * Convert DataRecord to CheckListItem
   * @return populated CheckListItem
   * @param dr data record
   */
  static convertCheckListItem(dr: DataRecord): CheckListItem {
    const result = this.createCheckListItem(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      Object.assign(result, dr.changeMap);
    }
    return result;
  } // convertCheckListItem


  /**
   * Convert json to CheckList
   * @return populated CheckList
   * @param json json info
   */
  static createCheckList(json: any): CheckList {
    const entity: CheckList = new CheckList();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'code') {
        entity.code = value;
      } else if (key === 'isApplyOnlyOnce') {
        entity.isApplyOnlyOnce = Boolean(value);
      } else if (key === 'isManualOnly') {
        entity.isManualOnly = Boolean(value);
      } else if (key === 'lastModifiedDate') {
        entity.lastModifiedDate = Number(value);
      } else if (key === 'onlyIfValid') {
        entity.onlyIfValid = value;
      } else if (key === 'onlyforObject') {
        entity.onlyforObject = value;
      } else if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      }
    } // for loop
    return entity;
  } // createCheckList


  /**
   * Convert json to CheckListItem
   * @return populated CheckListItem
   * @param json json info
   */
  static createCheckListItem(json: any): CheckListItem {
    const entity: CheckListItem = new CheckListItem();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'checkList') {
        entity.checkList = this.createCheckList(value); // dm
      } else if (key === 'checkListId') {
        entity.checkListId = Number(value);
      } else if (key === 'checkListSfId') {
        entity.checkListSfId = value;
      } else if (key === 'code') {
        entity.code = value;
      } else if (key === 'subject') {
        entity.subject = value;
      } else if (key === 'dateOffsetDays') {
        entity.dateOffsetDays = Number(value);
      } else if (key === 'isWorkdays') {
        entity.isWorkdays = Boolean(value);
      } else if (key === 'isDependentsAllComplete') {
        entity.isDependentsAllComplete = Boolean(value);
      } else if (key === 'isRequired') {
        entity.isRequired = Boolean(value);
      } else if (key === 'priority') {
        entity.priority = value;
      } else if (key === 'recordType') {
        entity.recordType = value;
      } else if (key === 'type') {
        entity.type = value;
      } else if (key === 'userSfId') {
        entity.userSfId = value;
      } else if (key === 'userDynamic') {
        entity.userDynamic = value;
      } else if (key === 'prerequisiteSfId') {
        entity.prerequisiteSfId = value;
      } else if (key === 'prerequisiteIdList') {
        entity.prerequisiteIdList = value;
      } else if (key === 'dependentSfId') {
        entity.dependentSfId = value;
      } else if (key === 'lastModifiedDate') {
        entity.lastModifiedDate = Number(value);
      }
    } // for loop
    return entity;
  } // createCheckListItem

} // Checklist4sfUtil
